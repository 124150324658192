import './App.css';

function App() {
  return (
    <div className='content'>
      <img src="/logo.png" alt="logo" />
      <p>A Mondain's Legacy UO Shard, enhanced with modern conveniences from later eras, including vendor search, full buff icons, and more.</p>
      <p>By Reetus, the creator of the <a href="https://www.github.com/Reetus/ClassicAssist">ClassicAssist</a> assistant for ClassicUO, and <a href="https://demisesearch.reetus.net">Demise Search</a></p>
      <p>&nbsp;</p>
      <p>Visit us soon at our upcoming site.</p>
      <p>&nbsp;</p>
      <p><a href="https://cdn.uocrossroads.net/Releases/CrossroadsLauncher-win-Setup.exe">Download</a> our Windows launcher, try via <a href="https://play.classicuo.org/?shard=43">ClassicUO Web</a></p>
      <p>Or bring you own setup, login.uocrossroads.net,2593</p>
      <p>&nbsp;</p>
      <p><a href="https://discord.gg/JWpK2Msnrm"><img src="/discord.png" style={{ width: '200px' }} alt="Join Our Discord" /></a></p>
    </div>
  );
}

export default App;
